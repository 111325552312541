/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createContext, useState, useRef } from 'react';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import { formerService } from '../services';

export const FormerContext = createContext({});

export const FormerContextProvider = ({ children }) => {
  const toast = useRef(null);
  const manageModal = useRef(null);
  const modalRef = useRef(null);
  const [showSave, setShowSave] = useState(false);
  const [showSetting, setShowSetting] = useState(true);
  const [modal, setModal] = useState({ modalRef, manageModal, showModal: false });
  const [activePage, setActivePage] = useState(0);
  const [eventPoint, setEventPoint] = useState(null);
  const [pages, setPages] = useState(null);

  const toogleSave = () => {
    setShowSave((s) => !s);
  };

  const toogleSetting = () => {
    setShowSetting((s) => !s);
  };

  const updateNode = async (node, config) => {
    const temp = JSON.parse(node.config);
    const newConfig = { ...temp, pages: config };
    const payload = {
      name: node.name,
      case_type: node.case_type,
      node_type: node.node_type,
      description: node.description,
      config: JSON.stringify(newConfig),
      template: node.node_type === 'human-interface' ? node.template || 'EMPTY' : 'EMPTY',
    };
    // console.log('payload', payload);
    const { success } = await formerService.updateTemplate(node.templateId, payload);
    return success;
    // return false;
  };

  const showSnackbar = (severity, summary, detail, delay = 4000) => {
    return toast?.current?.show({
      severity,
      summary,
      detail,
      life: delay,
    });
  };

  const showModal = (content, header) => {
    setModal({
      ...modal,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const handleCloseModal = () => {
    setModal({
      ...modal,
      showModal: false,
    });
  };

  return (
    <FormerContext.Provider
      value={{
        showSave,
        showSnackbar,
        showModal,
        handleCloseModal,
        toogleSave,
        activePage,
        showSetting,
        setShowSetting,
        toogleSetting,
        pages,
        setPages,
        updateNode,
        setActivePage,
      }}
    >
      <Dialog
        className="modal"
        header={modal.manageModal.header}
        visible={modal.showModal}
        onHide={handleCloseModal}
      >
        {modal.manageModal.content}
      </Dialog>
      <Toast ref={toast} className="toast" />
      {children}
    </FormerContext.Provider>
  );
};
