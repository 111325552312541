/* eslint-disable no-empty-pattern */
import React from 'react';

import './Content.css';

const Content = ({}) => {
  return (
    <div className="template-content">
      <div>Content</div>
    </div>
  );
};

export default Content;
