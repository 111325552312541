/* eslint-disable import/prefer-default-export */
const DEFAULT_START = {
  general: {},
  exported_values: {},
};

const DEFAULT_HI = {
  pages: [],
  components: [],
  conditions: [],
  styling: [],
  integrations: [],
  assets: [],
};

const DEFAULT_SCRIPT = {
  general: {},
  script: {},
  exported_values: {},
};

const DEFAULT_EMAIL = {
  general: {},
  exported_values: {},
};

const DEFAULT_API = {
  general: {},
  exported_values: {},
};

const DEFAULT_TYPES = [
  { id: 'start', type: 'Start', conf: DEFAULT_START, color: '#759AAB' },
  { id: 'human-interface', type: 'Human Interface', conf: DEFAULT_HI, color: '#2E9D7A' },
  { id: 'email', type: 'Email', conf: DEFAULT_EMAIL, color: '#4DCCBD' },
  { id: 'api', type: 'API Service', conf: DEFAULT_API, color: '#3F2E56' },
  { id: 'script', type: 'Script', conf: DEFAULT_SCRIPT, color: '#92AFD7' },
];

export { DEFAULT_HI, DEFAULT_START, DEFAULT_SCRIPT, DEFAULT_EMAIL, DEFAULT_API, DEFAULT_TYPES };
