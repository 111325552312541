/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
import React, { createContext, useState, useEffect, useRef } from 'react';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { usersService, workSpaceService } from '../services';
import { getCookie, deleteCookie } from '../utils/tools';

export const AccountContext = createContext({});

const DEFAULT_FLOW = {
  _id: '',
  description: '',
  name: '',
  creator: '',
  type: '',
  WS: '',
};

const ColorTemplatesHex = ['#96AFD3', '#87A0B2', '#8796A7', '#868C9C', '#BFD3C1', '#BFD3C1'];

export const AccountContextProvider = ({ children }) => {
  const history = useHistory();
  const [userState, setUserState] = useState(null);
  const [workflowsByWs, setWorkflowsByWs] = useState([]);
  const [frontDeskForms, setFrontDeskForms] = useState([]);
  const [typesInfo, setTypesInfo] = useState([]);
  const [totalFlows, setTotalFlows] = useState(null);
  const [totalApps, setTotalApps] = useState(null);
  const [totalBatches, setTotalBatches] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [showSystemEmptyStates] = useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [infoCase, setInfoCase] = useState(null);
  const [isAccountInfoLoading, setIsAccountInfoLoading] = useState(true);
  const [enginesByWorkspace, setEnginesByWorkspace] = useState([]);
  const [membersRetrieved, setMembersRetrieved] = useState([]);
  const [selectedApp, setSelectedApp] = useState({});
  const [selectedFlow, setSelectedFlow] = useState(DEFAULT_FLOW);
  const [selectedCaseType, setSelectedCaseType] = useState(null);
  const [apps, setApps] = useState([]);
  const [modeles, setModeles] = useState([]);
  const [batches, setBatches] = useState(null);
  const [cases, setCases] = useState(null);
  const [forms, setForms] = useState([]);
  const [isFormerOpen, setIsFormerOpen] = useState(false);
  const toast = useRef(null);
  const manageModal = useRef(null);
  const [tools, setTools] = useState({ toast, manageModal, showModal: false, closable: true });

  const logOut = async () => {
    deleteCookie('credentials');
    setSelectedApp({});
    setApps([]);
    setModeles([]);
    setForms([]);
  };

  const showSnackbar = (severity, summary, detail, delay = 4000) => {
    return tools?.toast?.current?.show({
      severity,
      summary,
      detail,
      life: delay,
    });
  };

  const handleExit = (systemError = false) => {
    logOut();
    history?.push('/');
    if (systemError) {
      setTimeout(() => {
        showSnackbar('error', 'System unavailable!', 'Please try later');
      }, 2000);
    }
  };

  const handleResetFlow = () => {
    setSelectedFlow(DEFAULT_FLOW);
  };

  const getEnginesByWorkspace = async () => {
    const { workspaceData } = accountInfo;
    const { success, data: enginesByWorkspaceData } = await workSpaceService.getEnginesByWorkspace(
      workspaceData?.data?.id
    );
    if (success) {
      setEnginesByWorkspace(
        enginesByWorkspaceData.map((engine) => ({
          ...engine,
        }))
      );
    } else {
      return enginesByWorkspaceData;
    }
  };

  const getMembersByWorkspace = async (isDirect = true) => {
    if (accountInfo?.workspaceData) {
      const { workspaceData } = accountInfo;
      const { success, data } = await workSpaceService.getAllHumansByWorkspace(
        workspaceData?.data?.id,
        isDirect
      );
      if (success) {
        setMembersRetrieved(data.data);
      }
    }
  };

  const updateContextAccountInfo = async () => {
    setIsAccountInfoLoading(true);
    const { userId } = JSON.parse(getCookie('credentials'));
    const { success: userFlag, data: userResponse } = await usersService.getUser(userId);
    if (userFlag) {
      const { workspace, enabledModules } = userResponse;
      const {
        success: workspaceFlag,
        data: workspaceResponse,
      } = await workSpaceService.getWorkspace(workspace?.id);
      if (workspaceFlag) {
        setAccountInfo({
          userData: { ...userResponse, enabledModules },
          workspaceData: workspaceResponse,
        });
        const homeCalculete = 'dashboard';
        return homeCalculete;
      }
      // No workspace retrived
      handleExit(true);
    } else {
      // No user retrived
      handleExit(true);
    }
  };

  useEffect(() => {
    if (getCookie('credentials')) {
      updateContextAccountInfo();
    } else {
      handleExit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountInfo) {
      setIsAccountInfoLoading(false);
      getEnginesByWorkspace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  useEffect(() => {
    if (enginesByWorkspace?.length) {
      getMembersByWorkspace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enginesByWorkspace]);

  const handleTask = () => {
    // showSnackbar('info', 'TO-DO!', 'Dev-team working on this page');
    // console.log('Dev-team working on this page');
  };

  const handleCloseModal = () => {
    setTools({
      ...tools,
      showModal: false,
      selectedEditUser: null,
    });
  };

  return (
    <AccountContext.Provider
      value={{
        accountInfo,
        setAccountInfo,
        logOut,
        updateContextAccountInfo,
        isAccountInfoLoading,
        tools,
        setTools,
        showSnackbar,
        menuCollapse,
        setMenuCollapse,
        selectedApp,
        setSelectedApp,
        apps,
        setApps,
        modeles,
        setModeles,
        selectedFlow,
        setSelectedFlow,
        handleResetFlow,
        showSystemEmptyStates,
        ColorTemplatesHex,
        handleTask,
        handleCloseModal,
        forms,
        setForms,
        enginesByWorkspace,
        membersRetrieved,
        totalApps,
        setTotalApps,
        totalFlows,
        setTotalFlows,
        typesInfo,
        setTypesInfo,
        getMembersByWorkspace,
        batches,
        setBatches,
        totalBatches,
        setTotalBatches,
        workflowsByWs,
        setWorkflowsByWs,
        cases,
        setCases,
        selectedCaseType,
        setSelectedCaseType,
        infoCase,
        setInfoCase,
        showDetails,
        setShowDetails,
        frontDeskForms,
        setFrontDeskForms,
        userState,
        setUserState,
        isFormerOpen,
        setIsFormerOpen,
      }}
    >
      <Dialog
        header={tools.manageModal.header}
        visible={tools.showModal}
        className="modal"
        footer={tools.manageModal.footer}
        onHide={() => setTools({ ...tools, showModal: false })}
        closable={tools.closable}
      >
        {tools.manageModal.content}
      </Dialog>
      <Toast ref={toast} className="toast" />
      {children}
    </AccountContext.Provider>
  );
};

AccountContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
