/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { Loader } from '../../../components';
import { Button } from '../../../components/BasicComponents';
import { AccountContext } from '../../../contexts/AccountContext';
import { FormerContext } from '../../../contexts/FormerContext';
import { formerService } from '../../../services';
import Displayer from './Displayer/Displayer';
import Header from './Header/Header';
import Pages from './Pages/Pages';
import Settings from './Settings/Settings';

import './CreateTemplates.css';

function CreateTemplates({ location }) {
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const { setFrontDeskForms, setIsFormerOpen } = useContext(AccountContext);
  const { showSetting, setActivePage } = useContext(FormerContext);

  // Crear referencias para los componentes
  const headerRef = useRef(null);
  const pagesRef = useRef(null);
  const displayerRef = useRef(null);

  const getCreateTemplates = async () => {
    setIsloading(true);
    const { success, data: formsList } = await formerService.listTemplates();
    if (success) {
      setFrontDeskForms(formsList.data);
    } else {
      setFrontDeskForms(null);
    }
    setIsloading(false);
  };

  useEffect(() => {
    setIsFormerOpen(true);
    getCreateTemplates();
    if (!location?.template?.name) {
      history.goBack();
    }
    return () => {
      setIsFormerOpen(false);
    };
  }, [location, history, setIsFormerOpen]);
  useEffect(() => {
    setActivePage(0);
    return () => {};
  }, []);

  const handleGoBack = () => {
    history.goBack();
    setIsFormerOpen(false);
  };

  return !isLoading ? (
    <div className="create-template-container">
      <div className="create-template-body">
        <section className="layout">
          <div className="header">
            <Header ref={headerRef} location={location} title={location?.template?.name} />
          </div>
          <div className="leftSide">
            <Pages
              ref={pagesRef}
              title={location?.template?.name}
              config={location?.template?.config}
              node={location?.template}
            />
          </div>
          <div className="body">
            <Displayer
              ref={displayerRef}
              title={location?.template?.name}
              template={location?.template?.template}
              config={location?.template}
            />
          </div>
          {showSetting && (
            <div className="rightSide">
              <Settings title={location?.template?.name} />
            </div>
          )}
        </section>
      </div>
      <div className="create-template-empty">
        <div className="create-text bounce">
          The Template Builder is available exclusively on desktop.
        </div>
        <Button className="link-white bounce" onClick={handleGoBack}>
          Go back
        </Button>
      </div>
    </div>
  ) : (
    <div className="loader-wrapper">
      <Loader fit transparant />
    </div>
  );
}

CreateTemplates.propTypes = {};

export default CreateTemplates;
