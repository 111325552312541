/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */
import React, { useContext, useState, useEffect, memo } from 'react';

import HoundIcon from '../../../../assets/images/logo_icon_hound_transp.png';
import { GoBackComponent } from '../../../../components';
import { Button } from '../../../../components/BasicComponents';
import { FormerContext } from '../../../../contexts/FormerContext';

import './Header.css';

const Header = memo(({ location, title }) => {
  const {} = useContext(FormerContext);
  const [creationOn, setCreationOn] = useState(false);
  const [integrationOn, setIntegrationOn] = useState(false);
  const [shareOn, setShareOn] = useState(false);

  const showWorkspaceInfo = () => {
    // console.log('Workspace Data', accountInfo?.workspaceData.data);
  };

  useEffect(() => {
    if (location.pathname.endsWith('/create')) {
      setCreationOn(true);
    }
    if (location.pathname === '/integrate') {
      setIntegrationOn(true);
    }
    if (location.pathname === '/share') {
      setShareOn(true);
    }
    return () => {};
  }, []);

  const modulesFormer = () => {
    return (
      <div className="cont-header">
        <div className="flex-col-aligned">
          <Button onClick={null} className="header-btn">
            Create
          </Button>
          {creationOn && <span className="header-active"> </span>}
        </div>
        {/* <div className="flex-col-aligned">
          <Button onClick={null} className="header-btn">
            Integrate
          </Button>
          {integrationOn && <span className="header-active"> </span>}
        </div>
        <div className="flex-col-aligned">
          <Button onClick={null} className="header-btn">
            Preview
          </Button>
          {shareOn && <span className="header-active"> </span>}
        </div> */}
      </div>
    );
  };

  return (
    <div className="flex-header">
      <div className="logo-container">
        <Button type="button" onClick={showWorkspaceInfo} className="workspaceBtn">
          <GoBackComponent isWhite />
          <img src={HoundIcon} className="nav-user" title="Hound Software" alt="Hound logo" />
          <p className="workspace-txt">Human Interface Builder</p>
          {'>'}
          <p>{title}</p>
        </Button>
      </div>
      <div className="header-actions">{modulesFormer()}</div>
    </div>
  );
});

export default Header;
