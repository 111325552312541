/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */
import React, { useState, useContext, useEffect } from 'react';

import './Displayer.css';
import { Button } from '../../../../components/BasicComponents';
import { FormerContext } from '../../../../contexts/FormerContext';

const Displayer = ({ title, template, config }) => {
  const { showSetting, toogleSetting, setActivePage } = useContext(FormerContext);
  const [refEvent, setRefEvent] = useState(null);

  useEffect(() => {
    if (refEvent) {
      setTimeout(() => {
        refEvent?.window?.postMessage(
          {
            isTemplate2: true,
            template: 'Jaajaja',
          },
          '*'
        );
      }, 2000);
    }
    return () => {};
  }, [refEvent]);

  const handleMoveInTemplaste = (n) => {
    setActivePage((s) => (s === -1 ? 0 : s + n));
  };

  window.onmessage = function (event) {
    if (event.data.isBg) {
      setRefEvent(event?.source);
      event?.source.window?.postMessage(
        {
          isTemplate: true,
          template: config || 'JAJAJ',
        },
        '*'
      );
    }
    if (event.data.isMovin) {
      handleMoveInTemplaste(event.data.action);
    }
  };

  return (
    <div className="flex-left-container">
      <div className={!showSetting ? 'hide-sidebar' : 'hide-sidebar-extended'}>
        <Button
          icon={showSetting ? 'pi pi-arrow-right' : 'pi pi-arrow-left'}
          type="button"
          onClick={toogleSetting}
          className="settings-cls"
        />
      </div>
      <div className="builder-template-wrapper">
        <iframe
          id="template_maker"
          style={{ width: '100%', height: '100%', border: 'none' }}
          srcDoc={template}
          title="displaying the template"
        />
      </div>
    </div>
  );
};

export default Displayer;
