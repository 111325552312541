/* eslint-disable import/order */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect, memo, useCallback, useContext } from 'react';

import update from 'immutability-helper';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Button } from '../../../../components/BasicComponents';
import { AddPageInTemplate } from '../../../../forms';
import { Card } from './Card';
import { FormerContext } from '../../../../contexts/FormerContext';

import './Pages.css';

const ItemTypes = {
  CARD: 'card',
};

const style = {
  width: '100%',
};

const Pages = memo(({ config, node }) => {
  const {
    activePage,
    setActivePage,
    showModal,
    toogleSave,
    pages,
    setPages,
    updateNode,
  } = useContext(FormerContext);

  useEffect(() => {
    try {
      if (config) {
        const { pages: pag } = JSON.parse(config);
        setPages(pag);
      }
    } catch (error) {
      console.log('');
    }
    return () => {};
  }, []);

  const Container = memo(function Container() {
    const [cards, setCards] = useState(pages);
    const findCard = useCallback(
      (id) => {
        const card = cards.filter((c) => `${c.id}` === id)[0];
        return {
          card,
          index: cards.indexOf(card),
        };
      },
      [cards]
    );
    const moveCard = useCallback(
      (id, atIndex) => {
        const { card, index } = findCard(id);
        const temp = update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        });
        const updatedTemp = temp.map((item, newIndex) => ({
          ...item,
          id: newIndex + 1,
          isEnd: newIndex === temp.length - 1,
          isStart: newIndex === 0,
        }));
        setCards(updatedTemp);
        const data = updateNode(node, updatedTemp);
        // if (data) {
        //   console.log('A');
        // } else {
        //   console.log('B');
        // }
      },
      [findCard, cards, setCards]
    );
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

    return (
      <div ref={drop} style={style} className="page-wrapper">
        {cards?.map((card) => (
          <Card
            key={card.id}
            id={`${card.id}`}
            text={card.name}
            isEnd={card.isEnd}
            isStart={card.isStart}
            moveCard={moveCard}
            findCard={findCard}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        ))}
      </div>
    );
  });

  const forms = [<AddPageInTemplate />];

  const HeaderForm = ({ title, iconName = '' }) => {
    return <div className="activity-header">{title}</div>;
  };

  return (
    <div className="flex-left-container2">
      <div className="flex-pager2">
        <h3 className="pager-title">Pages</h3>
        {pages?.length > 0 && (
          <Button
            className="add-ticket-btn"
            label=""
            icon="pi pi-plus"
            style={{ margin: '0px' }}
            onClick={() => showModal(forms[0], <HeaderForm title="New Page" />)}
          />
        )}
      </div>
      <hr />
      {pages?.length > 0 ? (
        <DndProvider backend={HTML5Backend}>
          <Container />
        </DndProvider>
      ) : (
        <div className="empty-cls">
          <p>No pages found!</p>
          <div className="flex-row">
            <Button
              className="add-ticket-btn"
              label=""
              id="name"
              icon="pi pi-plus"
              style={{ margin: '0px' }}
              onClick={() => showModal(forms[0], <HeaderForm title="New Page" />)}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default Pages;
