/* eslint-disable no-constant-condition */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable new-cap */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import InfoTicket from '../../assets/svgs/info.svg';
import SettingDetail from '../../containers/HumanInterface/SettingDetail/SettingDetail';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { ToolsContext } from '../../contexts/ToolsContext';
import { EditATicketForm, MemberForm } from '../../forms';
import { casesDashboardService, humansService, workSpaceService } from '../../services';
import tools from '../../utils/time';
import { handleCopyClipboard } from '../../utils/utils';
import { Button, DataTable, Column } from '../BasicComponents';

const DEFAULT_FILTERS = {
  id: { value: null, matchMode: 'contains' },
  'operator.name': { value: null, matchMode: 'in' },
  'group.id': { value: null, matchMode: 'in' },
  status: { value: null, matchMode: 'in' },
  light_indicator: { value: null, matchMode: 'in' },
};

const AccordionItem = ({ index, title, amount, content, legend, type, headerColor, len }) => {
  const history = useHistory();
  const {
    styleGuideByGroupHex,
    teamSelected,
    setMemberSelected,
    groupsRetrieved,
    lockCase,
    unlockCase,
    setSelectedTicket,
    setTeamSelected,
    resolveCase,
    fetchOverAllCases,
    memberSelected,
    setHumansRetrieved,
  } = useContext(TicketsContext);
  const {
    accountInfo,
    showSnackbar,
    getMembersByWorkspace,
    setTools,
    tools: acTools,
    setSelectedCaseType,
    setInfoCase,
    setShowDetails,
    infoCase,
    setTypesInfo,
  } = useContext(AccountContext);
  const { showModal, handleCloseModal } = useContext(ToolsContext);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  function createBooleanArray(n) {
    return Array(n).fill(true);
  }

  const [managerFields, setManagerFields] = useState(createBooleanArray(len));
  // eslint-disable-next-line no-undef

  const retriveHumans = async () => {
    const { success, data: info } = await humansService.getHumansByWS();
    if (success) {
      setHumansRetrieved(info.data[0]);
    }
  };

  const ConditionColumn = ({ light_indicator, created_at, data, id: CaseId, status }) => {
    const difference = tools.timeSince(created_at?.seconds);
    const collectData = () => {
      const { initialValues } = data;
      const newData = {
        initialValues,
        exportedValues: { exit: 'approved' },
      };
      return JSON.stringify(newData);
    };

    const handleApprove = async () => {
      const isNext = true;
      const inputsForm = collectData();
      await resolveCase(CaseId, inputsForm, isNext);
      await fetchOverAllCases();
    };

    return (
      <div className="flex-rows">
        <div className={`dot2 ${light_indicator}`} title="Created since">
          <p className="dot-value">{difference}</p>
        </div>
        {status !== 'unassigned' && (
          <Button
            title="Ticket Info"
            className="approver"
            style={{ outline: 'none' }}
            onClick={(e) => {
              e.stopPropagation();
              handleApprove({ light_indicator, created_at, data, id: CaseId });
            }}
          >
            <span />
          </Button>
        )}
      </div>
    );
  };

  const filteredTeams = Array.isArray(groupsRetrieved)
    ? groupsRetrieved.filter((g) => {
        return !g?.name?.includes('MyBasket');
      })
    : [];

  const handleEditCase = (caseData) => {
    setTools({
      ...acTools,
      manageModal: {
        header: <HeaderForm titleForm="Edit the Ticket" />,
        content: (
          <EditATicketForm
            caseInfo={caseData}
            groupsRetrieved={filteredTeams}
            refresh={fetchOverAllCases}
          />
        ),
      },
      showModal: true,
    });
  };

  const handleCancel = async (id2) => {
    const result = await resolveCase(
      id2,
      JSON.stringify({
        initialValues: {},
        exportedValues: { exit: 'canceled' },
      }),
      false
    );
    if (result) {
      showSnackbar('success', '', 'Ticket canceled!', 3000);
    } else {
      showSnackbar('error', '', 'Ticket could not be canceled!', 3000);
    }
    await fetchOverAllCases();
    handleCloseModal();
  };

  const ContentHero = ({
    contact,
    operation,
    id: id2,
    group,
    description,
    creation,
    light_indicator,
    due_date,
    created_at,
    status,
    operator,
  }) => {
    return (
      <div className={`details-case-hero `}>
        <table className="details-case-table">
          <>
            <tr className="details-case-tr">
              <th className="details-case-th">Ticket ID</th>
              <td className="details-case-td">{id2}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Operator</th>
              {operator?.name ? (
                <td className="details-case-td">{operator?.name}</td>
              ) : (
                <div className="badge-no-assined">Unassigend</div>
              )}
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Contact</th>
              <td className="details-case-td">{contact}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Case ID</th>
              <div className="flex-row">
                <td className="details-case-td">{operation}</td>
                <Button
                  title="Copy ID"
                  className="button-action-share"
                  style={{
                    color: styleGuideByGroupHex[group.id?.slice(-1)],
                  }}
                  icon="pi pi-share-alt"
                  onClick={() => {
                    handleCopyClipboard(operation);
                    showSnackbar('info', '', 'Case ID copied!', 3000);
                  }}
                />
              </div>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Team</th>
              <td className="details-case-td">{group.name}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Description</th>
              <td className="details-case-td">{description}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Created</th>
              <td className="details-case-td">
                {creation}
                <div
                  className={`dot2 ${light_indicator}`}
                  title="Created since"
                  style={{ marginLeft: '10px' }}
                >
                  <p className="dot-value">{tools.timeSince(created_at?.seconds)}</p>
                </div>
              </td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Due date</th>
              <td className="details-case-td">{tools.timeSince(due_date?.seconds)}</td>
            </tr>
          </>
        </table>
        {type !== 'ticket' && (
          <div className="actioners-tickets">
            <Button
              title="Cancel ticket"
              className="btn-delete"
              icon="pi pi-trash"
              onClick={() => {
                handleCancel(id2);
              }}
            >
              Cancel
            </Button>
            {operator?.name && (
              <Button
                title="Cancel ticket"
                className="unassigned-arrow"
                onClick={() => {
                  unlockCase(id2);
                  fetchOverAllCases();
                  handleCloseModal();
                }}
              >
                Unassign
              </Button>
            )}
            <Button
              title="Unassign the ticket"
              onClick={() => {
                handleCloseModal();
                handleEditCase({
                  contact,
                  operation,
                  id: id2,
                  group,
                  description,
                  creation,
                  light_indicator,
                  due_date,
                  created_at,
                  status,
                });
              }}
            >
              Reassign
            </Button>
          </div>
        )}
      </div>
    );
  };

  const ActionsColumn = ({
    status,
    id: idCase,
    operation,
    contact_name,
    group,
    description,
    creation,
    due_date,
    created_at,
    light_indicator,
    contact,
    operator,
  }) => {
    return (
      <>
        {status !== 'unassigned' ? (
          <div className="ticket-actions">
            <Button
              title="Ticket Info"
              className="assigned-arrow"
              style={{ outline: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                showModal(
                  <ContentHero
                    contact={contact}
                    contact_name={contact_name}
                    operation={operation}
                    id={idCase}
                    group={group}
                    description={description}
                    creation={creation}
                    due_date={due_date}
                    created_at={created_at}
                    light_indicator={light_indicator}
                    status={status}
                    operator={operator}
                  />,
                  <HeaderForm titleForm="Ticket Info" />
                );
              }}
            >
              <img className="assigned-arrow" src={InfoTicket} alt="" />
            </Button>
            {type === 'ticket' && (
              <Button
                title="Unassign the ticket"
                className="unassigned-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  unlockCase(idCase);
                  handleCloseModal();
                }}
              >
                Unclaim
              </Button>
            )}
          </div>
        ) : (
          <div className="ticket-actions">
            <Button
              title="Ticket Info"
              className="assigned-arrow"
              style={{ outline: 'none' }}
              onClick={(e) => {
                e.stopPropagation();
                showModal(
                  <ContentHero
                    contact={contact}
                    contact_name={contact_name}
                    operation={operation}
                    id={idCase}
                    group={group}
                    description={description}
                    creation={creation}
                    due_date={due_date}
                    created_at={created_at}
                    light_indicator={light_indicator}
                  />,
                  <HeaderForm titleForm="Ticket Info" />
                );
              }}
            >
              <img className="assigned-arrow" src={InfoTicket} alt="" />
            </Button>
            {type === 'ticket' && (
              <Button
                title="Assign the ticket"
                className="unassigned-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  lockCase(idCase);
                  handleCloseModal();
                }}
              >
                Claim
              </Button>
            )}
          </div>
        )}
      </>
    );
  };

  const GroupColumn = ({ group }) => {
    try {
      if (groupsRetrieved) {
        const data = (groupsRetrieved || [])?.filter((g) => {
          return g.id === group.id;
        });
        return (
          <div style={{ background: data[0]?.colorTeam || 'gray' }} className={`groupChip `}>
            {group.name}
          </div>
        );
      }
    } catch (error) {
      // console.log(error);
    }
  };

  function toggleBooleanAtIndex(i) {
    if (i < 0 || i >= managerFields.length) {
      console.error('Índice fuera de rango');
      return managerFields; // Devolver el arreglo sin cambios si el índice está fuera de rango
    }

    const newArray = [...managerFields]; // Crear una copia del arreglo para no modificar el original
    newArray[i] = !newArray[i]; // Toggle del valor booleano en el índice dado
    setManagerFields(newArray);
  }

  const onTicketSelect = (app) => {
    setSelectedTicket(app);
    history.push({
      pathname: `/tickets/${app.id}`,
      state: { ticket: app },
    });
  };

  // const cols = [
  //   { field: 'id', header: 'TicketId' },
  //   { field: 'light_indicator', header: 'Time Color' },
  //   { field: 'operation', header: 'Ticket' },
  //   { field: 'description', fieldValue: 'id', header: 'Description' },
  //   { field: 'status', header: 'Status' },
  // ];

  // const exportColumns = cols.map((col) => {
  //   return {
  //     title: col.header,
  //     dataKey: col.field,
  //   };
  // });

  // const exportPdf = (title2) => {
  //   import('jspdf').then((jsPDF) => {
  //     import('jspdf-autotable').then(() => {
  //       const doc = new jsPDF.default(0, 0);
  //       doc.autoTable(exportColumns, content);
  //       doc.save(`Tickets-${accountInfo?.userData?.name}-${title2}-${new Date()}.pdf`);
  //     });
  //   });
  // };

  const CaseCard = ({ cases }) => {
    return (
      <div className="modeler-table2">
        <DataTable
          emptyMessage="No tickets found."
          value={cases}
          globalFilterFields={['id', 'light_indicator', 'operator.name', 'group.name', 'status']}
          responsiveLayout="scroll"
          paginator={cases?.length > 10}
          rows={10}
          filters={type === 'ticket' ? filters : filters}
          resizableColumns
          scrollable
          exportCSV
          selectionMode={type === 'ticket' ? 'single' : ''}
          onSelectionChange={(e) => type === 'ticket' && onTicketSelect(e.value)}
          removableSort
        >
          <Column
            field="light_indicator"
            filterField="light_indicator"
            header=""
            headerStyle={{
              maxWidth: '10vw',
              minWidth: '30px',
              width: '10vw',
              textAlign: 'center',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            body={ConditionColumn}
            bodyStyle={{ textAlign: 'left', overflow: 'visible' }}
            sortable
          />
          <Column
            field="operation"
            header="Case ID"
            headerStyle={{
              maxWidth: '10vw',
              minWidth: '50px',
              width: '90px',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'left',
            }}
          />
          <Column
            field="group.id"
            filterField="group.id"
            header="Actions"
            headerStyle={{
              maxWidth: '10vw',
              minWidth: '100px',
              width: '10vw',
              textAlign: 'center',
              backgroundColor: '#22384d',
              color: 'white',
              height: '45px',
            }}
            body={ActionsColumn}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              height: '40px',
            }}
          />
          <Column
            field="description"
            header="Description"
            headerStyle={{
              maxWidth: '15vw',
              minWidth: '330px',
              width: '15vw',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'left',
            }}
          />
          <Column
            field="group.id"
            filterField="group.id"
            header="Team"
            headerStyle={{
              maxWidth: '2vw',
              minWidth: '150px',
              width: '2vw',
              textAlign: 'center',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            body={GroupColumn}
            bodyStyle={{
              textAlign: 'center',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            sortable
          />
        </DataTable>
      </div>
    );
  };

  const GroupDetails = () => <SettingDetail type="team" data={teamSelected} />;

  const HumanDetailsCmp = ({ memberData }) => <SettingDetail type="operators" data={memberData} />;

  const GroupCard = ({ groups = [] }) => {
    const handleSelection = (g) => {
      if (type === 'teams') {
        setTeamSelected(g);
      }
      if (type === 'operators') {
        setMemberSelected(g || null);
      }
    };
    return groups.length > 0 ? (
      (groups || [])?.map((g, i) => {
        if (type === 'teams' && !g.name.includes('MyBasket')) {
          const { id: ticketId, name: ticketName, colorTeam } = g;
          return (
            <Button
              id={ticketId}
              key={`${ticketName}-${ticketId}-${i}`}
              className="bagde-wrapper-group"
              onClick={(e) => {
                e.stopPropagation();
                handleSelection(g);
                setMemberSelected(null);
                setInfoCase(null);
                setSelectedCaseType(null);
                setShowDetails(true);
                // handleClick();
                // showModal(<GroupDetails data={g} />, <HeaderForm titleForm="Team Info" />);
              }}
            >
              <div
                className={g?.id === teamSelected?.id ? 'card-container-seleted' : 'card-container'}
                style={{ backgroundColor: '#3e678e', color: 'white' }}
              >
                <div className="badge-der-contact-name" title="Team Name">
                  {ticketName || g}
                </div>
                {colorTeam && <div className="node-cls" style={{ backgroundColor: colorTeam }} />}
              </div>
            </Button>
          );
        }
        if (type === 'operators') {
          const { id: ticketId, name, colorOperator } = g;
          return (
            <Button
              id={ticketId}
              key={`${name}-${ticketId}-${i}`}
              className={`bagde-wrapper-group `}
              onClick={(e) => {
                e.stopPropagation();
                handleSelection(g);
                setTeamSelected(null);
                setInfoCase(null);
                setSelectedCaseType(null);
                setShowDetails(true);
                // handleClick();
                // showModal(<HumanDetailsCmp memberData={g} />, <HeaderForm titleForm="Member Info" />);
              }}
            >
              <div
                className={
                  g?.human_id === memberSelected?.human_id
                    ? 'card-container-seleted'
                    : 'card-container'
                }
                style={{ backgroundColor: colorOperator || '#3e678e' }}
              >
                <div className="badge-der-contact-name" title="Member Name">
                  {`${name}`}
                </div>
              </div>
            </Button>
          );
        }
        if (type === 'cases' && g.id !== 'TBD') {
          const { id: ticketId, description, colorCaseType } = g;
          return (
            <Button
              id={ticketId}
              key={`${description}-${ticketId}-${i}`}
              className={`bagde-wrapper-group `}
              onClick={(e) => {
                e.stopPropagation();
                handleSelection(g);
                setSelectedCaseType(g);
                setTeamSelected(null);
                setMemberSelected(null);
                setShowDetails(false);
                // handleClick();
                // showModal(<HumanDetailsCmp memberData={g} />, <HeaderForm titleForm="Member Info" />);
              }}
              style={{
                padding: '15px',
                maxWidth: '100%',
                borderRadius: '8px',
              }}
            >
              <div
                className={g?.id === infoCase?.id ? 'card-container-seleted' : 'card-container'}
                style={{ backgroundColor: colorCaseType || '#3e678e', color: 'white' }}
              >
                <div className="badge-der-contact-name" title="Case Type">
                  {`${description}`}
                </div>
              </div>
            </Button>
          );
        }
        return null;
      })
    ) : (
      <div className="no-results bounce">No results found</div>
    );
  };

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header bounce">{titleForm}</div>;
  };

  const findGreenSatus = (cases) => {
    const greenOnes = cases.filter((c) => c?.light_indicator === 'green').length;
    return greenOnes || '0';
  };

  const findYellowSatus = (cases) => {
    const greenOnes = cases.filter((c) => c?.light_indicator === 'yellow').length;
    return greenOnes || '0';
  };

  const findRedSatus = (cases) => {
    const greenOnes = cases.filter((c) => c?.light_indicator === 'red').length;
    return greenOnes || '0';
  };

  const addHumanToWorkspace = async (human) => {
    const { workspaceData } = accountInfo;
    const response = await workSpaceService.linkHumanWorkspace(workspaceData?.data?.id, {
      human: {
        id: human,
      },
    });
    if (response === 200) {
      retriveHumans();
      showSnackbar('success', '', 'Member added!', 3000);
      getMembersByWorkspace();
      handleCloseModal();
    }
  };

  const onClickMember = (value) => {
    console.log('onClickMember', value);
  };

  const createAHuman = async (human) => {
    const { success: humanInfoFlag, data: humanInfoResponse } = await humansService.createHuman(
      human
    );
    if (humanInfoFlag && humanInfoResponse.data.result === 'existing') {
      showSnackbar(
        'info',
        '',
        `Email already used by ${humanInfoResponse.data.name}/${humanInfoResponse.data.human_id}!`,
        5000
      );
    } else if (humanInfoFlag && humanInfoResponse.data.result === 'new') {
      addHumanToWorkspace(humanInfoResponse.data.human_id);
    } else {
      showSnackbar('error', '', `Failed member creation!`, 5000);
    }
  };

  const getListCasesTypes = async () => {
    const { success, data: CasesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      setTypesInfo(CasesInfo);
    }
  };

  const SwitchAccordion = ({ title3 }) => {
    switch (type) {
      case 'ticket-manager':
        return (
          <div className="accordion-wrapper-item">
            <Button
              onClick={() => toggleBooleanAtIndex(index)}
              className={`accordion-toggler ${headerColor}`}
              title={legend}
            >
              <div className="accordion-txt">
                <p className="accordion-item">{title3 === '<nil>' ? 'Unassigned' : title3}</p>
                {+findRedSatus(content) ? (
                  <p title="alert" className="ticket-amount-btn red">
                    {`${findRedSatus(content)}`}
                  </p>
                ) : null}
                {+findYellowSatus(content) ? (
                  <p title="warning" className="ticket-amount-btn yellow">
                    {`${findYellowSatus(content)}`}
                  </p>
                ) : null}
                {+findGreenSatus(content) ? (
                  <p title="recent" className="ticket-amount-btn green">
                    {`${findGreenSatus(content)}`}
                  </p>
                ) : null}
              </div>
              <div className="flex-row">
                {amount ? (
                  <p title="Total" className="ticket-amount-btn">
                    {`${amount} ticket(s)`}
                  </p>
                ) : (
                  <></>
                )}
                <i
                  className={`pi ${
                    !managerFields[index] ? 'pi-angle-up' : 'pi-angle-down'
                  } accordion-toggler-icon`}
                />
                {/* {amount !== 0 && (
                  <Button
                    title="Download"
                    type="button"
                    icon="pi pi-file-pdf"
                    className="pdf"
                    rounded
                    onClick={(e) => {
                      e.stopPropagation();
                      exportPdf(title3);
                    }}
                  />
                )} */}
              </div>
            </Button>
            {managerFields[index] && (
              <div className="accordion-wrapper-list">
                <CaseCard cases={content} title2={title3} />
              </div>
            )}
          </div>
        );
      case 'ticket':
        return (
          <div className="accordion-wrapper-item">
            <Button
              onClick={() => toggleBooleanAtIndex(index)}
              className={`accordion-toggler ${headerColor}`}
              title={legend}
            >
              <div className="accordion-txt">
                <p className="accordion-item">{title3}</p>
                {+findRedSatus(content) ? (
                  <p title="alert" className="ticket-amount-btn red">
                    {`${findRedSatus(content)}`}
                  </p>
                ) : null}
                {+findYellowSatus(content) ? (
                  <p title="warning" className="ticket-amount-btn yellow">
                    {`${findYellowSatus(content)}`}
                  </p>
                ) : null}
                {+findGreenSatus(content) ? (
                  <p title="recent" className="ticket-amount-btn green">
                    {`${findGreenSatus(content)}`}
                  </p>
                ) : null}
              </div>
              <div className="flex-row">
                {amount ? (
                  <p title="Total" className="ticket-amount-btn">
                    {`${amount} ticket(s)`}
                  </p>
                ) : (
                  <></>
                )}
                <i
                  className={`pi ${
                    !managerFields[index] ? 'pi-angle-up' : 'pi-angle-down'
                  } accordion-toggler-icon`}
                />
                {/* {amount !== 0 && (
                  <Button
                    title="Download"
                    type="button"
                    icon="pi pi-file-pdf"
                    className="pdf"
                    rounded
                    onClick={(e) => {
                      e.stopPropagation();
                      exportPdf(title3);
                    }}
                  />
                )} */}
              </div>
            </Button>
            {managerFields[index] && (
              <div className="accordion-wrapper-list">
                <CaseCard cases={content} title2={title3} />
              </div>
            )}
          </div>
        );
      case 'operators':
        return (
          <div className="accordion-wrapper-item">
            <Button
              onClick={() => toggleBooleanAtIndex(index)}
              className={`accordion-toggler ${headerColor}`}
              title={legend}
            >
              <div className="accordion-row">
                <div className="flex-row">
                  <p className="roles-txt2">Members</p>
                </div>
                <div className="flex-row">
                  <h2 className="roles-txt2">
                    <div className="roles-len">{amount}</div>
                  </h2>
                  <Button
                    className="add-ticket-btn"
                    icon="pi pi-plus"
                    onClick={(e) => {
                      e.stopPropagation();
                      const memberForm = (
                        <MemberForm
                          isNewMember
                          isEditingMember
                          handleSubmit={createAHuman}
                          onClickMember={onClickMember}
                        />
                      );
                      showModal(memberForm, <HeaderForm titleForm="Add member" />);
                    }}
                  />
                  <i
                    className={`pi ${
                      !managerFields[index] || amount === 0 ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon white-color`}
                  />
                </div>
              </div>
            </Button>
            {managerFields[index] && (
              <div className="accordion-wrapper-list adjust-field">
                <GroupCard groups={content} />
              </div>
            )}
          </div>
        );
      case 'teams':
        return (
          <div className="accordion-wrapper-item">
            <Button
              onClick={() => toggleBooleanAtIndex(index)}
              className={`accordion-toggler ${headerColor}`}
              title={legend}
            >
              <div className="accordion-row">
                <div className="flex-row">
                  <p className="roles-txt2">{`Teams `}</p>
                </div>

                <div className="flex-row">
                  <h2 className="roles-txt2">
                    <div className="roles-len">{amount}</div>
                  </h2>
                  <i
                    className={`pi ${
                      !managerFields[index] || amount === 0 ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon white-color`}
                  />
                </div>
              </div>
            </Button>
            {managerFields[index] && (
              <div className="accordion-wrapper-list adjust-field">
                <GroupCard groups={content} />
              </div>
            )}
          </div>
        );
      case 'cases':
        return (
          <div className="accordion-wrapper-item">
            <Button
              onClick={() => toggleBooleanAtIndex(index)}
              className={`accordion-toggler ${headerColor}`}
              title={legend}
            >
              <div className="accordion-row">
                <div className="flex-row">
                  <p className="roles-txt2">Case Types</p>
                </div>

                <div className="flex-row">
                  <h2 className="roles-txt2">
                    <div className="roles-len">{amount}</div>
                  </h2>
                  <i
                    className={`pi ${
                      !managerFields[index] || amount === 0 ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon white-color`}
                  />
                </div>
              </div>
            </Button>
            {managerFields[index] && (
              <div className="accordion-wrapper-list adjust-field">
                <GroupCard groups={content} />
              </div>
            )}
          </div>
        );

      default:
        return <></>;
    }
  };
  return <SwitchAccordion title3={title} />;
};

AccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  legend: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
};

export default AccordionItem;
