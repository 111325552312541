/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */
import React, { useState } from 'react';

import { TabView, TabPanel } from '../../../../components';
import Constraints from './Constraints/Constraints';
import Content from './Content/Content';

import './Settings.css';

const Settings = ({ title }) => {
  const [tabIndex, setTabIndex] = useState();
  return (
    <div className="flex-left-container3">
      <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)} scrollable>
        <TabPanel header="Content">
          <Content />
        </TabPanel>
        <TabPanel header="Constraints">
          <Constraints />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Settings;
