import React from 'react';

import { AccountContextProvider } from './contexts/AccountContext';
import { FormerContextProvider } from './contexts/FormerContext';
import { ResponsiveContextProvider } from './contexts/ResponsiveContext';
import { TicketsContextProvider } from './contexts/TicketsContext';
import { ToolsContextProvider } from './contexts/ToolsContext';
import Routes from './routes';

const App = () => {
  return (
    <AccountContextProvider>
      <ResponsiveContextProvider>
        <TicketsContextProvider>
          <ToolsContextProvider>
            <FormerContextProvider>
              <Routes />
            </FormerContextProvider>
          </ToolsContextProvider>
        </TicketsContextProvider>
      </ResponsiveContextProvider>
    </AccountContextProvider>
  );
};

export default App;
