/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import { memo } from 'react';

import { useDrag, useDrop } from 'react-dnd';

// import InfoTicket from '../../../../assets/svgs/info.svg';
// import { Button } from '../../../../components/BasicComponents';

const style = {
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  cursor: 'move',
  with: '100%',
};

const ItemTypes = {
  CARD: 'card',
};

// eslint-disable-next-line import/prefer-default-export
export const Card = memo(function Card({
  id,
  text,
  moveCard,
  findCard,
  isEnd,
  isStart,
  activePage,
  setActivePage,
}) {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  const opacity = isDragging ? 0 : 1;

  const handleShowApp = () => {
    setActivePage(originalIndex);
  };
  console.log(activePage === originalIndex);
  return (
    <div
      type="button"
      className={`page-wrap ${isStart ? ' borderStart ' : ''} ${isEnd ? ' borderEnd ' : ''} ${
        activePage === originalIndex ? ' sebasEnd ' : ''
      }`}
      ref={(node) => drag(drop(node))}
      style={{
        ...style,
        opacity,
      }}
      onClick={handleShowApp}
    >
      <div className="flex-row">
        <p>{text}</p>
        {isStart && <span className="start-badge">Start</span>}
        {isEnd && <span className="end-badge">End</span>}
        {activePage === originalIndex && <span className="active-badge">Active</span>}
      </div>
      {/* <Button className="pager-options" type="button">
        <img className="assigned-arrow" src={InfoTicket} alt="" />
      </Button> */}
    </div>
  );
});
