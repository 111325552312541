/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/named */
import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import HoundIcon from '../../assets/images/logo_icon_hound_transp.png';
import HoundLogo from '../../assets/svgs/arrows-vertical.svg';
import HoundWA from '../../assets/svgs/whatsapp.svg';
import LogoutIcon from '../../assets/nav-icons/LogoutIcon';
import HoundGear from '../../assets/svgs/gear.svg';
import HoundLogout from '../../assets/svgs/box-arrow-left.svg';
import { Header } from '../../components';
import { Button } from '../../components/BasicComponents';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import './Dashboard.css';
import { TicketsContext } from '../../contexts/TicketsContext';

const Dashboard = ({ children, routes }) => {
  const history = useHistory();
  const {
    accountInfo,
    logOut,
    menuCollapse,
    setAccountInfo,
    setSelectedApp,
    setSelectedFlow,
    setApps,
    setModeles,
    setForms,
    userState,
    setMenuCollapse,
    isFormerOpen,
  } = useContext(AccountContext);
  const [settingsOn, setSettingsOn] = useState(false);
  const [WAOn, setWAOn] = useState(false);
  const {
    setCaseSelected,
    setTeamSelected,
    setMemberSelected,
    setGroupsRetrieved,
    setOverall,
  } = useContext(TicketsContext);
  const { isTablet, isMobile } = useContext(ResponsiveContext);
  const { name } = accountInfo?.userData || {};
  const { data } = accountInfo?.workspaceData || {};

  const showUserInfo = () => {
    // console.log('User Data', accountInfo?.userData);
  };

  useEffect(() => {
    if (children.props.location.pathname === '/admin-info') {
      setSettingsOn(true);
    }
    if (children.props.location.pathname === '/WA') {
      setWAOn(true);
    }
    return () => {};
  }, []);

  const resetContexts = () => {
    setAccountInfo(null);
    setSelectedApp({});
    setSelectedFlow({});
    setApps([]);
    setModeles([]);
    setForms([]);
    setCaseSelected({});
    setTeamSelected(null);
    setMemberSelected(null);
    setGroupsRetrieved({});
    setOverall([]);
  };

  const handleLogout = (isExpired = false) => {
    logOut();
    resetContexts();
    history.push({ pathname: '/', isExpired });
  };

  const handleGoToWA = () => {
    history.push({ pathname: '/WA' });
  };

  const handleGoToSettings = () => {
    history.push({ pathname: '/admin-info' });
  };

  const userRoutes = () => {
    const customTag = () => {
      switch (userState) {
        case 0:
          return { title: 'Active', styling: 'bagde-activity' };
        case 1:
          return { title: 'Inactive', styling: 'bagde-activity bagde-yellow' };
        case 2:
          return { title: 'Expired', styling: 'bagde-activity bagde-red' };
        default:
          return { title: 'Active', styling: 'bagde-activity' };
      }
    };
    const { title, styling } = customTag();
    return (
      <div className="cont-header">
        <Button onClick={handleLogout} className={isMobile || isTablet ? 'userBtn2' : 'userBtn'}>
          <img src={HoundLogout} title="Hound Software" alt="Hound logo" />
        </Button>
        <div className="flex-col-aligned">
          <Button onClick={handleGoToWA} className={isMobile || isTablet ? 'userBtn2' : 'userBtn'}>
            <img src={HoundWA} title="Hound Software" alt="Hound logo" />
          </Button>
          {WAOn && <span className="header-active"> </span>}
        </div>
        <div className="flex-col-aligned">
          <Button
            type="button"
            title="Info User"
            onClick={handleGoToSettings}
            className={isMobile || isTablet ? 'userBtn2' : 'userBtn'}
          >
            <img
              src={HoundGear}
              className="nav-userMobile"
              title="Hound Software"
              alt="Hound logo"
            />
          </Button>
          {settingsOn && <span className="header-active"> </span>}
        </div>
        <Button
          type="button"
          title="Info User"
          onClick={showUserInfo}
          className={isMobile || isTablet ? 'userBtn2' : 'userBtn'}
        >
          {isMobile || isTablet ? (
            <p className="username-txt">{(name[0] + name[1]).toLocaleUpperCase()}</p>
          ) : (
            <p className="username-txt">{name}</p>
          )}
          <span title={title} className={styling} />
        </Button>
      </div>
    );
  };

  const toogleMenu = () => {
    setMenuCollapse((m) => !m);
  };

  const showWorkspaceInfo = () => {
    // console.log('Workspace Data', accountInfo?.workspaceData.data);
  };

  return (
    <div className="root">
      <div className="app-container">
        {!isFormerOpen && <Header routes={routes} />}
        <div className="app-content">
          {!isFormerOpen && (
            <div className="header-container">
              <div className="logo-container">
                {!menuCollapse && (
                  <Button
                    type="button"
                    onClick={showWorkspaceInfo}
                    className={isMobile || isTablet ? 'workspaceBtn2' : 'workspaceBtn'}
                    title={`Active Workspace ${data?.name}`}
                  >
                    <img
                      src={HoundIcon}
                      className={isMobile || isTablet ? 'nav-userMobile' : 'nav-user'}
                      title="Hound Software"
                      alt="Hound logo"
                    />
                    {isMobile || isTablet ? (
                      <p className="workspace-txt">
                        {(data?.name[0] + data?.name[1]).toLocaleUpperCase()}
                      </p>
                    ) : (
                      <p className="workspace-txt">{data?.name}</p>
                    )}
                  </Button>
                )}
                <Button className="sebs" onClick={toogleMenu}>
                  <img
                    src={HoundLogo}
                    className="img-logo"
                    title="Hound Software"
                    alt="Hound logo"
                  />
                </Button>
              </div>
              <div className="header-actions">{userRoutes()}</div>
            </div>
          )}
          <div className="body">
            <div className="app-wrapper">
              <section className="page-container">{children}</section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.array.isRequired,
};

export default Dashboard;
