/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import ReactJson from 'react-json-view';

import { Button, InputText, Dropdown, InputTextarea } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { formerService } from '../../services';
import './TemplateForm.css';
import { DEFAULT_TYPES } from './contants';

const TemplateForm = ({ refreshTemplateForms, data, isEditing = false }) => {
  const [templateId, setTemplateId] = useState(null);
  const [name, setName] = useState('');
  const [config, setConfig] = useState(null);
  const [descript, setDescript] = useState(null);
  const [template, setTemplate] = useState(null);
  const { handleCloseModal, typesInfo, showSnackbar } = useContext(AccountContext);
  const [selectedCaseType, setSelectedCaseType] = useState(null);
  const [type, setType] = useState(null);
  const [seeJSON, setSeeJSON] = useState(false);

  const onsubmit = async () => {
    let payload;
    if (isEditing) {
      payload = {
        name,
        case_type: selectedCaseType?.id,
        node_type: type?.id,
        description: descript,
        config,
        template: type?.id === 'human-interface' ? template || 'EMPTY' : 'EMPTY',
      };
      const { success } = await formerService.updateTemplate(templateId, payload);
      if (success) {
        refreshTemplateForms();
        showSnackbar('success', '', `Template edited successfully!`, 3000);
        handleCloseModal();
      } else {
        showSnackbar('error', '', 'Template could not be edited!', 3000);
      }
    } else {
      payload = {
        name,
        case_type: selectedCaseType?.id,
        description: descript,
        config,
        node_type: type?.id,
        template: 'EMPTY',
      };
      const { success } = await formerService.createTemplate(payload);
      if (success) {
        refreshTemplateForms();
        showSnackbar('success', '', `Template created!`, 3000);
        handleCloseModal();
      } else {
        showSnackbar('error', '', 'Template could not be created!', 3000);
      }
    }
  };

  const onTypeChange = (e) => {
    setSelectedCaseType(e.value);
  };

  const onTypeTemplateChange = (e) => {
    setType(e.value);
  };

  const rolesTemplate = (option) => {
    return <div className="flex option-backgroung">{option.description}</div>;
  };

  const templatesTemplate = (option) => {
    return <div className="flex option-backgroung">{option.type}</div>;
  };

  const toogleConfig = () => {
    setSeeJSON((s) => !s);
  };

  const getTypeConfig = (typeConfig) => {
    const info = DEFAULT_TYPES.filter((t) => t.type === typeConfig);
    return JSON.stringify(info[0].conf, null, 2) || '{}';
  };

  useEffect(() => {
    if (isEditing) {
      const selectedCaseTypeInfo = typesInfo.filter((t) => {
        return data.case_type === t.id;
      });
      setName(data?.name);
      setConfig(data?.config);
      setDescript(data?.description);
      setTemplateId(data?.templateId);
      setTemplate(data?.template);
      const info2 = DEFAULT_TYPES.filter((t) => t.id === data?.node_type);
      setType(info2[0]);
      setSelectedCaseType({
        id: selectedCaseTypeInfo[0]?.id,
        description: selectedCaseTypeInfo[0]?.description,
      });
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!isEditing && type?.type) {
      setConfig(getTypeConfig(type?.type));
    }
    return () => {};
  }, [type]);

  let temp = {};
  try {
    temp = JSON.parse(config);
  } catch (error) {
    console.log(error);
  }

  return (
    <section className="add-batch-section">
      <div className="front-app-container">
        {isEditing && (
          <div className="front-app-container">
            <label htmlFor="name" className="inputs-label">
              Node ID
            </label>
            <input
              id="id"
              name="id"
              autoComplete="off"
              spellCheck={false}
              onBlur={(e) => setTemplateId(e.target.value)}
              onChange={(e) => setTemplateId(e.target.value)}
              value={templateId}
              placeholder="Node ID"
              type="text"
              className="input-form"
              disabled
            />
          </div>
        )}
      </div>
      <div className="front-app-container">
        <label className="inputs-label">Case Type</label>
        <div className="flex-row2">
          <InputText
            disabled
            type="text"
            value={selectedCaseType?.description}
            className="app-form-inputText"
            placeholder="Select a case type"
          />
          <Dropdown
            className="user-dropdown"
            value={selectedCaseType}
            options={typesInfo}
            onChange={onTypeChange}
            optionLabel="description"
            itemTemplate={rolesTemplate}
          />
        </div>
      </div>
      <div className="front-app-container">
        <label className="inputs-label">Template Type</label>
        <div className="flex-row2">
          <InputText
            disabled
            type="text"
            value={type?.type}
            className="app-form-inputText"
            placeholder="Select a template type"
          />
          <Dropdown
            className="user-dropdown"
            value={type}
            options={DEFAULT_TYPES}
            onChange={onTypeTemplateChange}
            optionLabel="type"
            itemTemplate={templatesTemplate}
          />
        </div>
      </div>
      <div className="front-app-container">
        <label htmlFor="name" className="inputs-label">
          Name
        </label>
        <input
          id="name"
          name="name"
          autoComplete="off"
          spellCheck={false}
          onBlur={(e) => setName(e.target.value)}
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Enter the template name"
          type="text"
          className="input-form"
        />
      </div>
      <div className="front-app-container">
        <label htmlFor="name" className="inputs-label">
          Description
        </label>
        <input
          id="description"
          name="description"
          autoComplete="off"
          spellCheck={false}
          onBlur={(e) => setDescript(e.target.value)}
          onChange={(e) => setDescript(e.target.value)}
          value={descript}
          placeholder="Enter the template description"
          type="text"
          className="input-form"
        />
      </div>
      {type?.type && (
        <div className="front-app-container">
          <div className="flex-front-app-container">
            <label htmlFor="name" className="inputs-label2">
              Configuration
            </label>
            <Button
              type="button"
              className={seeJSON ? 'pi pi-lock json-cls' : 'pi pi-unlock json-cls'}
              onClick={() => {
                toogleConfig();
              }}
              label=""
            />
          </div>
          {seeJSON ? (
            <InputTextarea
              id="template"
              name="template"
              autoComplete="off"
              spellCheck={false}
              onBlur={(e) => setConfig(e.target.value)}
              onChange={(e) => setConfig(e.target.value)}
              value={config}
              placeholder="Enter the config template"
              type="text"
              className={isEditing ? 'input-form-textarea2' : 'input-form-textarea'}
            />
          ) : (
            <div className="input-contain">
              <ReactJson
                src={temp}
                name="config"
                enableClipboard
                // collapsed
                onEdit={(e) => {
                  setConfig(JSON.stringify(e.updated_src));
                }}
                onAdd={(e) => {
                  setConfig(JSON.stringify(e.updated_src));
                }}
                onDelete={(e) => {
                  setConfig(JSON.stringify(e.updated_src));
                }}
              />
            </div>
          )}
        </div>
      )}
      {isEditing && type?.id === 'human-interface' && (
        <div className="front-app-container">
          <label htmlFor="name" className="inputs-label">
            Template
          </label>
          <InputTextarea
            id="template"
            name="template"
            autoComplete="off"
            spellCheck={false}
            onBlur={(e) => setTemplate(e.target.value)}
            onChange={(e) => setTemplate(e.target.value)}
            value={template}
            placeholder="Enter the template"
            type="text"
            className={isEditing ? 'input-form-textarea2' : 'input-form-textarea'}
          />
        </div>
      )}
      <div className="footer-batch">
        <Button
          type="button"
          className="app-batch-back"
          onClick={() => {
            handleCloseModal();
          }}
          label="Back"
        />
        <Button
          disabled={
            isEditing
              ? !(name && selectedCaseType?.description && descript && config && templateId)
              : !(name && selectedCaseType?.id && descript && config)
          }
          onClick={onsubmit}
          className="app-batch-btn"
          label={isEditing ? 'Save' : 'Create template'}
        />
      </div>
    </section>
  );
};

export default TemplateForm;
