/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';

import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';

import { GoBackComponent, Loader } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  vendorsService,
  docuSignService,
  casesDashboardService,
  batchesService,
} from '../../../services';
import Camera from '../../Tooler/Camera/Camera';
import FileDisplayer from '../../Tooler/FileDisplayer/FileDisplayer';
import Notes from '../../Tooler/Notes/Notes';
import SignaturePad from '../../Tooler/SignaturePad/SignaturePad';
import YesNoPopup from '../../Tooler/YesNoPopup/YesNoPopup';
import CaseDetail from '../CaseDetail/CaseDetail';
import './TicketResolution.css';

const WHITE_COLOR = 'white';
const TicketResolution = () => {
  const info = useWindowSize();
  const history = useHistory();
  const [colorBgTemplate, setColorBgTemplate] = useState(WHITE_COLOR);
  const {
    selectedTicket,
    setSelectedTicket,
    resolveCase,
    saveS3Case,
    getS3Case,
    casesById,
    casesByUser,
  } = useContext(TicketsContext);
  const manageTooler = useRef(null);
  const manageNotes = useRef(null);
  const manageSubMenu = useRef(null);
  const toolerRef = useRef(null);
  const notesRef = useRef(null);
  const submenuRef = useRef(null);
  const manageDisplayFileRef = useRef(null);
  const displayFileRef = useRef(null);
  const caseRef = useRef(null);
  const headerRef = useRef(false);
  const [batchId, setBatchId] = useState(null);
  const { setMenuCollapse, showSnackbar, accountInfo } = useContext(AccountContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [tooler, setTooler] = useState({ toolerRef, manageTooler, showTooler: false });
  const [submenu, setSubmenu] = useState({ submenuRef, manageSubMenu, showTooler: false });
  const [notes, setNotes] = useState({ notesRef, manageNotes, showNotes: false });
  const [displayerFile, setDisplayerFile] = useState({
    displayFileRef,
    manageDisplayFileRef,
    showDisplayFile: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = accountInfo;

  const Header = () => {
    return <div className="blue-bg" />;
  };

  if (!selectedTicket) {
    history.push('/tickets');
    setSelectedTicket(null);
  }

  useEffect(() => {
    if (info.width < 570) {
      setMenuCollapse(false);
    } else {
      setMenuCollapse(true);
    }
  }, [info]);

  const showTooler = (content, header) => {
    setTooler({
      ...tooler,
      manageTooler: { content, header },
      showTooler: true,
    });
  };

  const handleCloseTooler = () => {
    setTooler({
      ...tooler,
      showTooler: false,
    });
  };

  const handleCloseNotes = () => {
    setNotes({
      ...notes,
      showNotes: false,
    });
  };

  const showNotes = (content, header) => {
    setNotes({
      ...notes,
      manageNotes: { content, header },
      showNotes: true,
    });
  };

  const showSubMenu = (content, header) => {
    setSubmenu({
      ...submenu,
      manageSubMenu: { content, header },
      showSubMenu: true,
    });
  };

  const handleCloseDisplayerFile = () => {
    setDisplayerFile({
      ...displayerFile,
      showDisplayFile: false,
    });
  };

  const showDisplayerFile = (content, header) => {
    setDisplayerFile({
      ...displayerFile,
      manageDisplayFileRef: { content, header },
      showDisplayFile: true,
    });
  };

  const handleFile = ({ data }) => {
    const FileContent = (
      <FileDisplayer event={data} handleCloseDisplayerFile={handleCloseDisplayerFile} />
    );
    showDisplayerFile(FileContent, null);
  };

  // const HeaderForm = ({ title }) => {
  //   return <div className="activity-header bounce">{`${title}`}</div>;
  // };

  const handleSignature = (events) => {
    const SignForm = <SignaturePad events={events} />;
    showTooler(SignForm, null);
  };

  useEffect(async () => {
    const templateAsync = await casesById(selectedTicket?.id);
    if (templateAsync) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    return () => {};
  }, []);

  const handleCloseSubMenu = () => {
    setSubmenu({
      ...submenu,
      showSubMenu: false,
    });
  };
  const handleShoot = ({ type, color, txts, event }) => {
    const ticketForm = (
      <Camera
        type={type}
        data={[]}
        color={color}
        handleCloseTooler={handleCloseTooler}
        disclaimersTxt={txts}
        event={event}
      />
    );
    showTooler(ticketForm, <Header />);
  };

  const handleUserValidation = ({ event, userName, className, msg }) => {
    const YesNoForm = (
      <YesNoPopup
        event={event}
        userName={userName}
        className={className}
        msg={msg}
        handleCloseTooler={handleCloseTooler}
      />
    );
    showTooler(YesNoForm, null);
  };

  // const DEFAULT_SET_TOOLS = ['ID Front', 'ID Back', 'Face', 'Passport', 'Minoridad', 'Residencia'];

  const cleanHash = () => {
    const result = window.location.hash.split('/');
    if (result.length === 2) {
      const popHash = result[0];
      window.location.hash = popHash;
    }
  };

  const collectData = (setOfData, fileIds) => {
    const { data } = selectedTicket;
    const { initialValues } = data;
    let newData;
    if (fileIds) {
      newData = {
        initialValues,
        exportedValues: { ...setOfData, s3Info: JSON.stringify({ fileIds }), exit: 'approved' },
      };
    } else {
      newData = {
        initialValues,
        exportedValues: { ...setOfData, exit: 'approved' },
      };
    }

    return JSON.stringify(newData);
  };

  const handleCancel = async () => {
    await resolveCase(
      selectedTicket.id,
      {
        initialValues: {},
        exportedValues: { exit: 'canceled' },
      },
      false
    );
    await casesByUser();
  };

  const adaptData = (data, state, caseId, status, wsId) => {
    const updatedData = {
      state,
      caseId,
      status,
      wsId,
      processed: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        updatedData[`retrived_${key}`] = data[key];
      }
    }
    return updatedData;
  };

  const handleApprove = async (setOfData, event, data) => {
    const { id, status } = selectedTicket;
    const isNext = true;
    if (status === 'assigned') {
      let inputsForm;
      let S3Saved;
      let isS3Saved;
      if (data?.uploadFiles) {
        S3Saved = await saveS3Case({
          caseId: data?.caseId,
          files: data?.files,
        });
        isS3Saved = Array.isArray(S3Saved?.data);
      }
      if (isS3Saved || !data?.uploadFiles) {
        inputsForm = collectData(setOfData);
      } else {
        data?.uploadFiles &&
          showSnackbar(
            'error',
            '',
            '¡Hemos tenido problemas guardando los archivos! Inténlelo mas tarde.'
          );
        event.window.postMessage(
          {
            isApi: true,
            success: isS3Saved,
            type: data.type,
          },
          '*'
        );
        return;
      }
      const result = await resolveCase(id, inputsForm, isNext);
      if (result) {
        const { state, status: statusTemp } = data?.context;
        const tempValues = {};
        if (isS3Saved) {
          tempValues.files = JSON.stringify(S3Saved?.data);
        }
        const info2 = adaptData(
          {
            ...data?.values,
            ...tempValues,
          },
          state,
          data?.caseId,
          statusTemp,
          data?.wsId
        );
        await batchesService.updateBatchCase(data?.caseId, info2);
      }
      if (result) {
        showSnackbar('success', '', 'Ticket sent!');
      } else {
        showSnackbar('error', '', 'Ticket could not be sent!');
      }
      event.window.postMessage(
        {
          isApi: true,
          success: result,
          type: data.type,
        },
        '*'
      );
    } else {
      showSnackbar('info', '', 'Assign/claim the ticket before solving it.');
      cleanHash();
      setMenuCollapse(true);
      history.push('/tickets');
    }
  };

  const getNotes = async (caseID) => {
    const { success: s2, data: d2 } = await casesDashboardService.getCaseNotes(caseID);
    if (s2) {
      return d2;
    }
    return false;
  };

  const handleExternalCalls = async (event) => {
    let payload;
    switch (event?.data?.type) {
      case 'docMB':
        payload = await vendorsService.callExternal({
          url: event.data?.url,
          verb: event.data?.verb,
          body: {
            tipCon: event.data?.tipCon,
            dui: event.data.payload.doc,
          },
        });
        break;
      case 'docusign-create':
        payload = await docuSignService.createEnvelope(event?.data?.caseId, event?.data?.payload);
        break;
      case 'docusign-get':
        const dataPayload = {
          caseId: event?.data?.caseId,
          docusignId: event?.data?.docuSignId,
          payload: {
            ticket: {
              id: selectedTicket.id,
            },
            signer: event?.data?.signer,
            params: {
              urlCallback: event?.data?.callbackTo,
            },
          },
        };
        payload = await docuSignService.generateUrlEnvelope(dataPayload);
        break;
      case 's3-get':
        payload = await getS3Case({
          caseId: event.data.caseId,
        });
        break;
      case 'notes-get':
        payload = await getNotes(event.data.caseId);
        break;
      default:
        payload = {};
        break;
    }
    event?.source?.window.postMessage(
      {
        isExternalCall: true,
        type: event?.data?.type,
        subtype: event?.data?.subtype,
        payload,
        error: !payload?.success,
      },
      '*'
    );
  };

  const getUserInfo = (event) => {
    const userInfo = {
      email: userData?.email,
      name: userData?.name,
    };
    event?.window.postMessage({ userHoundInfo: true, userInfo }, '*');
  };

  const handleNotes = (event) => {
    if (event.data.caseId && event.data.caseId !== '<nil>') {
      const NotesContent = (
        <Notes
          event={event.source}
          isEditing={event.data.isEditing}
          note={event.data.note}
          handleCloseNotes={handleCloseNotes}
          caseId={event.data.caseId}
        />
      );
      showNotes(NotesContent, null);
    } else {
      showSnackbar('info', '', 'Template with no case id.');
    }
  };

  window.onmessage = function (event) {
    if (event.data.isBatch) {
      setBatchId(event.data.batchId);
    }
    if (event.data.isEvent) {
      // event.window.postMessage(
      //   {
      //     isApi: true,
      //     success: result2?.success ? result && result2?.success : result,
      //     type: data.type,
      //   },
      //   '*'
      // );
      handleCancel();
      // window.close();
    }
    if (event.data.isSignature) {
      handleSignature(event);
    }
    if (event.data.isNotes) {
      handleNotes(event);
    }
    if (event.data.isDisplayFile) {
      handleFile({
        data: event.data,
      });
    }
    if (event.data.userHoundInfo) {
      getUserInfo(event.source);
    }
    if (event.data.isYou) {
      handleUserValidation({
        event: event.source,
        userName: event.data.userName,
        className: event.data.className,
        msg: event?.data?.msg || '',
      });
    }
    if (event.data.showHeader) {
      headerRef.current = true;
    }
    // Send the form and save the S3
    if (event.data.isReady) {
      handleApprove(event.data.values, event.source, event.data);
    }
    // Fill the a tool state
    if (event.data.isOperation) {
      handleShoot({
        type: event.data.typeOperation,
        primaryColor: event.data.primaryColor,
        txts: event.data.txts,
        event: event.source,
      });
    }
    // Display a notification system
    if (event.data.isNotification) {
      showSnackbar(
        event.data.typeNotification || 'info',
        event.data.titleNotification || '',
        event.data.msgNotification || '',
        event.data.delay || '6000'
      );
    }
    if (event.data.isExternalCall) {
      handleExternalCalls(event);
    }
    if (event.data.isBg) {
      if (event.data.colorBg) {
        setColorBgTemplate(event.data.colorBg || WHITE_COLOR);
      } else {
        setColorBgTemplate(WHITE_COLOR);
      }
    }
  };

  useEffect(() => {
    if (selectedTicket) {
      caseRef.current = <CaseDetail />;
    }
    return () => {
      caseRef.current = null;
    };
  }, [selectedTicket]);

  return !isLoading ? (
    <div className="modeler-iframe-wrapper2" style={{ backgroundColor: colorBgTemplate }}>
      <div className="header-wrap">
        <GoBackComponent callback="/tickets" title="Go back" isWhite={false} />
      </div>
      {headerRef.current}
      <div className="ticket-iframe-iframe">
        <div className="ticket-iframe">
          <div className="HI-details">{caseRef.current}</div>
        </div>
      </div>
      <Dialog
        closable
        header={notes?.manageNotes?.header}
        className="notesForm"
        onHide={handleCloseNotes}
        visible={notes.showNotes}
      >
        {notes.manageNotes.content}
      </Dialog>
      <Dialog
        header={tooler?.manageTooler?.header}
        className="toolForm"
        onHide={handleCloseTooler}
        visible={tooler.showTooler}
      >
        {tooler.manageTooler.content}
      </Dialog>
      <Dialog
        header={submenu?.manageSubmenu?.header}
        className="submenu-popup"
        onHide={handleCloseSubMenu}
        visible={submenu.showSubMenu}
      >
        {submenu.manageSubMenu.content}
      </Dialog>
      <Dialog
        closable={false}
        header={displayerFile?.manageDisplayFileRef?.header}
        className="displayer-file"
        onHide={handleCloseDisplayerFile}
        visible={displayerFile?.showDisplayFile}
      >
        {displayerFile?.manageDisplayFileRef.content}
      </Dialog>
    </div>
  ) : (
    <Loader fit transparant />
  );
};

TicketResolution.propTypes = {};

export default TicketResolution;
