import { httpRequest, wrap } from './tools';
// TODO make urls constants

const createTemplate = async (payload) => {
  const fn = async () => {
    const url = `/templates`;
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const getTemplate = async (templateId) => {
  const fn = async () => {
    const url = `/templates/${templateId}`;
    const response = await httpRequest(url, 'GET', null, true);
    return response.data;
  };

  return wrap(fn);
};

const listTemplates = async () => {
  const fn = async () => {
    const url = `/templates`;
    const response = await httpRequest(url, 'GET', null, true);
    return response.data;
  };

  return wrap(fn);
};

const updateTemplate = async (templateId, payload) => {
  const fn = async () => {
    const url = `/templates/${templateId}`;
    const response = await httpRequest(url, 'PATCH', payload, true);

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { createTemplate, getTemplate, listTemplates, updateTemplate };
