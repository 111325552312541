/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

import './AddPageInTemplate.css';

const AddPageInTemplate = ({ handleClose, isEditing }) => {
  return <div>AddPageInTemplate</div>;
};

export default AddPageInTemplate;
