/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

// import formsSamples from '../../assets/html/sampleForm.html';

import { Button, InputText, Dropdown, InputTextarea } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { formsService } from '../../services';
import './FrontdeskForm.css';

const FrontdeskForm = ({ refreshFrontdeskForms, data, isEditing = false }) => {
  const [formId, setFormId] = useState(null);
  const [name, setName] = useState('');
  const [template, setTemplate] = useState(null);
  const [descript, setDescript] = useState(null);
  const [webhookLink, setWebhookLink] = useState(null);
  const [link, setLink] = useState(null);
  const { handleCloseModal, typesInfo, showSnackbar, accountInfo } = useContext(AccountContext);
  const { userData } = accountInfo;
  const [selectedCaseType, setSelectedCaseType] = useState(null);

  const onsubmit = async () => {
    let payload;
    // console.log('selectedCaseType', selectedCaseType);
    if (isEditing) {
      payload = {
        name,
        case_type: selectedCaseType?.id,
        description: descript,
        template,
        link,
        webhook: webhookLink,
      };
      const { success, data: addInfo } = await formsService.updateForm(
        userData?.workspace?.id,
        formId,
        payload
      );
      if (success) {
        refreshFrontdeskForms();
        showSnackbar('success', '', `Form edited successfully!`, 3000);
        handleCloseModal();
      } else {
        showSnackbar('error', '', 'Form could not be edited!', 3000);
      }
    } else {
      payload = {
        name,
        case_type: selectedCaseType?.id,
        description: descript,
        template,
        link: `https://mybasket.app/${userData?.workspace?.id}/forms/`,
      };
      const { success, data: addInfo } = await formsService.createForm(
        userData?.workspace?.id,
        payload
      );
      if (success) {
        refreshFrontdeskForms();
        showSnackbar('success', '', `Form created!`, 3000);
        handleCloseModal();
      } else {
        showSnackbar('error', '', 'Form could not be created!', 3000);
      }
    }
  };

  const onTypeChange = (e) => {
    setSelectedCaseType(e.value);
  };

  const rolesTemplate = (option) => {
    return <div className="flex option-backgroung">{option.description}</div>;
  };

  useEffect(() => {
    if (isEditing) {
      const selectedCaseTypeInfo = typesInfo.filter((t) => {
        return data.case_type === t.id;
      });
      setName(data?.name);
      setTemplate(data?.template);
      setDescript(data?.description);
      setLink(data?.link);
      setFormId(data?.formId);
      setWebhookLink(data?.webhook);
      setSelectedCaseType({
        id: selectedCaseTypeInfo[0]?.id,
        description: selectedCaseTypeInfo[0]?.description,
      });
    }
    return () => {};
  }, []);

  return (
    <section className="add-batch-section">
      <div className="front-app-container">
        {isEditing && (
          <div className="front-app-container">
            <label htmlFor="name" className="inputs-label">
              Form ID
            </label>
            <input
              id="id"
              name="id"
              autoComplete="off"
              spellCheck={false}
              onBlur={(e) => setFormId(e.target.value)}
              onChange={(e) => setFormId(e.target.value)}
              value={formId}
              placeholder="Description form"
              type="text"
              className="input-form"
              disabled
            />
          </div>
        )}
      </div>

      <div className="front-app-container">
        <label className="inputs-label">Case Type</label>
        <div className="flex-row2">
          <InputText
            disabled
            type="text"
            value={selectedCaseType?.description}
            className="app-form-inputText"
            placeholder="Select a case type"
          />
          <Dropdown
            className="user-dropdown"
            value={selectedCaseType}
            options={typesInfo}
            onChange={onTypeChange}
            optionLabel="description"
            itemTemplate={rolesTemplate}
          />
        </div>
      </div>
      <div className="front-app-container">
        <label htmlFor="name" className="inputs-label">
          Name
        </label>
        <input
          id="name"
          name="name"
          autoComplete="off"
          spellCheck={false}
          onBlur={(e) => setName(e.target.value)}
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Enter the name form"
          type="text"
          className="input-form"
        />
      </div>
      <div className="front-app-container">
        <label htmlFor="name" className="inputs-label">
          Description
        </label>
        <input
          id="description"
          name="description"
          autoComplete="off"
          spellCheck={false}
          onBlur={(e) => setDescript(e.target.value)}
          onChange={(e) => setDescript(e.target.value)}
          value={descript}
          placeholder="Enter the description form"
          type="text"
          className="input-form"
        />
      </div>
      {isEditing && (
        <div className="front-app-container">
          <label htmlFor="name" className="inputs-label">
            Webhook notification
          </label>
          <input
            id="webhookLink"
            name="description"
            autoComplete="off"
            spellCheck={false}
            onBlur={(e) => setWebhookLink(e.target.value)}
            onChange={(e) => setWebhookLink(e.target.value)}
            value={webhookLink}
            placeholder="Enter the Webhook form"
            type="text"
            className="input-form"
          />
        </div>
      )}
      {isEditing && (
        <div className="front-app-container">
          <label htmlFor="name" className="inputs-label">
            Link access
          </label>
          <input
            id="link"
            name="description"
            autoComplete="off"
            spellCheck={false}
            onBlur={(e) => setLink(e.target.value)}
            onChange={(e) => setLink(e.target.value)}
            value={link}
            placeholder="Enter the Link access form"
            type="text"
            className="input-form"
          />
        </div>
      )}
      <div className="front-app-container">
        <label htmlFor="name" className="inputs-label">
          Template
        </label>
        <InputTextarea
          id="template"
          name="template"
          autoComplete="off"
          spellCheck={false}
          onBlur={(e) => setTemplate(e.target.value)}
          onChange={(e) => setTemplate(e.target.value)}
          value={template}
          placeholder="Enter the template form"
          type="text"
          className={isEditing ? 'input-form-textarea2' : 'input-form-textarea'}
        />
      </div>
      <div className="footer-batch">
        <Button
          type="button"
          className="app-batch-back"
          onClick={() => {
            handleCloseModal();
          }}
          label="Back"
        />
        <Button
          disabled={
            isEditing
              ? !(name && selectedCaseType?.description && descript && template && link && formId)
              : !(name && selectedCaseType?.id && descript && template)
          }
          onClick={onsubmit}
          className="app-batch-btn"
          label={isEditing ? 'Save' : 'Create form'}
        />
      </div>
    </section>
  );
};

export default FrontdeskForm;
